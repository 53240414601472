import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import * as serviceWorker from "./serviceWorker";
import swDev from "./swDev";

import { icons } from "./assets/icons";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { Provider } from "react-redux";
import reducer from "./store/reducers/Reducer";
import configReducer from "./store/reducers/configReducer";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage";
// import Axios from "axios";
// import Cookie from "js-cookie";
// import jwt, { decode } from "jsonwebtoken";
import { HashRouter } from "react-router-dom";

React.icons = icons;

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["reducer"],
};

const rootReducer = combineReducers(
  {
    reducer: reducer,
    config: configReducer,
  },
  {
    devTools: false,
  }
);

const persisRe = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(persisRe, composeEnhancers(applyMiddleware(thunk)));

const persistor = persistStore(store);

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <HashRouter>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </HashRouter>
  </Provider>,
  // </React.StrictMode>
  document.getElementById("root")
);

// const app = (
//   <Provider store={store}>
//     <PersistGate persistor={persistor}>
//       <App />
//     </PersistGate>
//   </Provider>
// );
// ReactDOM.render(app, document.getElementById("root"));

// let token = Cookie.get("token");
// const jwt_secret =
//   "vCje7nlBn0EHepNGuwvaJCiWXoRAESCDpuWDWnEAFrPbtpGz18xOXbcKrSOOzHO7";

// if (token) {
//   jwt.verify(token, jwt_secret, (err, decoded) => {
//     if (err) {
//       Cookie.remove("token");
//       token = null;
//     } else {
//       decoded.iss = "http://localhost/pointofsale/api/auth/login";
//     }
//   });
// } else {
// }

// if (token) {
//   Axios.defaults.headers.common["Authorization"] = `Bearer${token}`;
//   Axios.post("/auth/me").then((resolve) => {
//     store.dispatch({
//       type: "SET_LOGIN",
//       logged_in: true,
//       user: resolve.data.user,
//       menus: resolve.data.menus,
//       insinfo: resolve.data.insinfo,
//     });
//     ReactDOM.render(app, document.getElementById("root"));
//   });
// } else {
//   ReactDOM.render(app, document.getElementById("root"));
// }

// serviceWorker.register();
swDev();
